import { useSelector } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';
import { setCredentials } from '../slices/authSlice';
import { useDispatch } from 'react-redux';
const isValidChinesePhone = (phone) => {
  const regex = /^1[3-9]\d{9}$/;
  return regex.test(phone);
};
const FloatForm = ({ phone: inheritPhone }) => {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!isValidChinesePhone(phone)) {
      toast.error('请输入正确的手机号码');
    } else {
      try {
        axios
          .patch(`/api/users/${userInfo._id}/phone`, {
            phone,
          })
          .then((res) => {
            dispatch(setCredentials({ ...res.data }));
            toast.success('updateUserPhone');
          })
          .catch((err) => toast.error(err?.data?.message || err.error));
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
    setPhone('');
  };
  if (!userInfo) {
    return null; // or a fallback UI, like a loading spinner
  }
  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className='my-2' controlId='phone'>
        <Form.Label>
          {process.env.REACT_APP_CHINESE ? '电话' : 'Phone'}
          {inheritPhone}
        </Form.Label>
        <Form.Control
          type='text'
          placeholder='输入手机号'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        ></Form.Control>
      </Form.Group>

      <Button type='submit' variant='primary'>
        {process.env.REACT_APP_CHINESE ? '更新电话' : 'Update'}
      </Button>
    </Form>
  );
};

export default FloatForm;
