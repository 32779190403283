import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const BagScreen = () => {
  const { id } = useParams(); // Get the bag ID from the URL
  const [bag, setBag] = useState(null); // State for bag details
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchBagDetails = async () => {
      try {
        const { data } = await axios.get(`/api/bags/${id}`); // Fetch bag details
        console.log(data);
        setBag(data);
      } catch (err) {
        setError(err.response?.data?.message || 'Error fetching bag details');
      } finally {
        setLoading(false);
      }
    };

    fetchBagDetails();
  }, [id]);

  if (loading) {
    return (
      <div className='text-center my-5'>
        <Spinner animation='border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant='danger'>{error}</Alert>;
  }

  return (
    <>
      <Link className='btn btn-light my-3' to='/'>
        Go Back
      </Link>
      <Card className='my-3 p-3 rounded'>
        <Card.Body>
          <Card.Title as='h2'>Bag Details</Card.Title>

          <Card.Text>
            <strong>Tracking Number:</strong> {bag.trackingNumber}
          </Card.Text>

          <Row className='my-3'>
            <Col md={6}>
              <Card.Text>
                <strong>Sender:</strong>
                <br />
                {bag.sender.name} <br />
                {bag.sender.address} <br />
                {bag.sender.phone}
              </Card.Text>
            </Col>
            <Col md={6}>
              <Card.Text>
                <strong>Recipient:</strong>
                <br />
                {bag.recipient.name} <br />
                {bag.recipient.address} <br />
                {bag.recipient.phone}
              </Card.Text>
            </Col>
          </Row>

          <Card.Text className='my-3'>
            <strong>Package Details:</strong>
            <br />
            Weight: {bag.packageDetails.weight} kg
            <br />
            Dimensions: {bag.packageDetails.dimensions.length} x{' '}
            {bag.packageDetails.dimensions.width} x{' '}
            {bag.packageDetails.dimensions.height} cm
            <br />
            Description: {bag.packageDetails.description || 'N/A'}
          </Card.Text>

          <Card.Text>
            <strong>Current Status:</strong> {bag.currentStatus}
          </Card.Text>
          <Card.Text>
            <strong>Delivered:</strong> {bag.isDelivered ? 'Yes' : 'No'}
          </Card.Text>

          <Card.Text className='text-muted'>
            <small>
              Created At: {new Date(bag.createdAt).toLocaleString()}
            </small>
            <br />
            <small>
              Last Updated: {new Date(bag.updatedAt).toLocaleString()}
            </small>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default BagScreen;
