import { BAGS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const bagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBags: builder.query({
      query: ({
        keyword: trackingNumber,
        phone,
        category,
        pageNumber: page,
        sort,
        filterStock,
      }) => ({
        url: BAGS_URL,
        params: {
          trackingNumber,
          phone,
          category,
          page,
          sort,
          filterStock,
        },
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Bags'],
    }),
    createBag: builder.mutation({
      query: () => ({
        url: `${BAGS_URL}`,
        method: 'POST',
      }),
      invalidatesTags: ['Bag'],
    }),
  }),
});

export const { useGetBagsQuery, useCreateBagMutation } = bagsApiSlice;
