import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const BagCard = ({ bag }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      <Card.Body>
        <Card.Title as='div'>
          <strong>Tracking Number:</strong> {bag.trackingNumber}
        </Card.Title>

        <Row className='mt-3'>
          <Col xs={12} md={6}>
            <Card.Text>
              <strong>Sender:</strong>
              <br />
              {bag.sender.name} <br />
              {bag.sender.address} <br />
              {bag.sender.phone}
            </Card.Text>
          </Col>
          <Col xs={12} md={6}>
            <Card.Text>
              <strong>Recipient:</strong>
              <br />
              {bag.recipient.name} <br />
              {bag.recipient.address} <br />
              {bag.recipient.phone}
            </Card.Text>
          </Col>
        </Row>

        <Card.Text className='mt-3'>
          <strong>Package Details:</strong>
          <br />
          Weight: {bag.packageDetails.weight} kg
          <br />
          Dimensions: {bag.packageDetails.dimensions.length} x{' '}
          {bag.packageDetails.dimensions.width} x{' '}
          {bag.packageDetails.dimensions.height} cm
          <br />
          Description: {bag.packageDetails.description || 'N/A'}
        </Card.Text>

        <Card.Text className='mt-3'>
          <strong>Status:</strong> {bag.currentStatus}
        </Card.Text>

        <Row>
          <Col>
            <Link to={`/bag/${bag._id}`} className='btn btn-primary'>
              View Details
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BagCard;
